<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 11:12:28
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-16 10:40:08
-->
<template>
  <div class="userCenterIndexPage">
    <el-card class="box-card" header="基本信息" shadow="never">
      <el-row class="baseInfo">
        <el-col :span="8">
          <div class="item">
            <label>用户名:</label>
            <span class="value">{{user.name}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label>手机号码:</label>
            <span class="value" @click="$router.push({path:'./editTel'})"> {{user.mobile}} <i class="el-icon-edit" style="color: #67c23a;font-size:18px"></i>
            </span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label>性别:</label>
            <span class="value"> {{user.gender?"男":"女"}} <span class="icon_gender" :class="{man:user.gender,woman:!user.gender}"></span>
            </span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label>证件类型:</label>
            <span class="value" v-if="user.papersType == 1">居民身份证</span>
            <span class="value" v-else-if="user.papersType == 2">台湾来往大陆通行证</span>
            <span class="value" v-else-if="user.papersType == 3">澳门来往大陆通行证</span>
            <span class="value" v-else-if="user.papersType == 4">香港来往大陆通行证</span>
            <span class="value" v-else-if="user.papersType == 5">护照</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label>证件号码:</label>
            <span class="value">{{user.idNumber|desensitization}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="item">
            <label>身份验证:</label>
            <el-link type="warning" class="value" v-if="user.certificationStatus == 'UNVERIFIED'">未认证</el-link>
            <el-link type="success" class="value" v-else-if="user.certificationStatus == 'AUTHENTICATIONOK'">认证成功</el-link>
            <el-link type="danger" class="value" v-if="user.certificationStatus == 'AUTHENTICATION_FAILURE'">认证失败</el-link>
          </div>
        </el-col>
      </el-row>
    </el-card>
    <el-card header="服务公司" class="box-card" shadow="never">
      <div class="line">
        <div class="title">所属公司：</div>
        <div class="companyInfo">
          <template v-if="ocompanyList.length">
            <div class="item" v-for="(item,index) in ocompanyList" :key="'ocompany'+index">
              <p>{{item}}</p>
            </div>
          </template>
          <template v-else>无</template>
        </div>
      </div>
      <div class="line">
        <div class="title">外派公司：</div>
        <div class="companyInfo">
          <template v-if="ocompanyList.length">
            <div class="item" v-for="(item,index) in ecompanyList" :key="'ecompany'+index" @click="$router.push({ path: '/userCenter/contracts' })">
              <p>{{item.ecompanyName}}</p>
              <el-button type="warning" size="mini" v-if="item.status=='wsign'">未签署</el-button>
              <el-button type="success" size="mini" v-else-if="item.status=='signed'">已签署</el-button>
              <el-button type="danger" size="mini" v-else-if="item.status=='unifiedSignSuccess'">企业统签</el-button>
            </div>
          </template>
          <template v-else>无</template>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" shadow="never">
      <div slot="header" class="clearfix">
        <span>我的收款账号</span>
        <el-link type="primary" v-if="cardListLength < 5" style="float: right" icon="el-icon-circle-plus" @click="addBankVisible = true">添加收款账号</el-link>
      </div>
      <bank-card @setCardNumber="cardListLength = $event" :addBankVisible='addBankVisible' :hasAlipay='hasAlipay' @setVisible="addBankVisible = $event" />
    </el-card>
    <!-- <el-card class="box-card" shadow="never" >
      <div slot="header" class="clearfix">
        <span>我的支付宝</span>
        <el-link type="primary" v-if="alipayLength == 0 && hasAlipay" style="float: right" icon="el-icon-circle-plus" @click="addAlipayVisible = true;">添加支付宝</el-link>
      </div>
      <alipay-card @setAlipayNumber="alipayLength = $event" :addAlipayVisible='addAlipayVisible' @setVisible="addAlipayVisible = $event" />
    </el-card> -->
    <el-card class="box-card" shadow="never" id="cardFour">
      <div slot="header" class="clearfix">
        <span>求职意向</span>
        <el-link type="primary" style="float: right" icon="el-icon-edit" @click="willingEditFlag = true">编辑</el-link>
      </div>
      <el-form ref="willingForm" :model="willingForm" label-width="80px" v-if="willingEditFlag" size="mini" class="willingForm">
        <el-form-item label="所在地区">
          <el-cascader v-model="willingForm.areaIdTemp" :options="cityOptions" :props="{value:'id',label:'text'}" @change="handleCityChange" ref="city"></el-cascader>
        </el-form-item>
        <el-form-item label="详细地址">
          <el-input v-model="willingForm.addr" placeholder="请输入详细地址"></el-input>
        </el-form-item>
        <el-form-item label="技能证书">
          <upload-file :fileList="willingForm.skillCertificateFiles" :limit="5" @setFile="willingForm.skillCertificateFiles = $event"></upload-file>
        </el-form-item>
        <el-form-item label="技能描述">
          <el-input type="textarea" v-model="willingForm.jobMemo" class="textarea" maxlength="1000" show-word-limit></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onWillingSubmit">保存</el-button>
          <el-button @click="handleCancel">取消</el-button>
        </el-form-item>
      </el-form>
      <el-form label-width="80px" class="detailForm" v-else>
        <el-form-item label="所在地区">{{user.areaFullName?user.areaFullName:"暂无"}}</el-form-item>
        <el-form-item label="详细地址">
          <div class="form_line">{{user.addr?user.addr:"暂无"}}</div>
        </el-form-item>
        <el-form-item label="技能证书">
          <upload-file v-if="willingForm.skillCertificateFiles" :fileList="willingForm.skillCertificateFiles" :readOnly="true"></upload-file>
          <div class="form_line" v-else>暂无</div>
        </el-form-item>
        <el-form-item label="技能描述">
          <div class="form_line">{{user.jobMemo?user.jobMemo:"暂无"}}</div>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import bankCard from "./components/bankCard.vue";
import alipayCard from "./components/alipayCard.vue";
import uploadFile from "@/components/upload/index.vue";
import api from "@/api/list";
import { mapGetters } from "vuex";
export default {
  components: {
    bankCard,
    alipayCard,
    uploadFile,
  },
  data() {
    return {
      cityOptions: [],
      willingEditFlag: false,
      willingForm: {
        id: undefined,
        areaFullName: undefined,
        areaId: undefined,
        areaIdTemp: [],
        addr: undefined,
        skillCertificateFiles: [],
        jobMemo: undefined,
      },
      ecompanyList: [], //外派公司
      ocompanyList: [], //所属公司
      cardListLength: 0,
      addBankVisible: false,
      addAlipayVisible: false,
      alipayLength: 0,
      hasAlipay:false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  watch: {
    user: {
      handler: function (v) {
        this.handleSetWillingForm(v);
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    api.getUserCompany().then((res) => {
      this.ecompanyList = res.data.ecompany;
      this.ocompanyList = res.data.ocompany;
    }); 

    //获取城市列表
    api.getAllCity().then((res) => {
      this.cityOptions = res.data;
    });



    //定位求职意向
    if (this.$route.query.position) {
      if (this.$route.query.position == "card_four") {
        this.$nextTick(() => {
          let cardFour = document.querySelector("#cardFour").offsetTop;
          this.willingEditFlag = true;
          setTimeout(() => {
            document.querySelector("html").scrollTop = cardFour;
          }, 500);
        });
      }
    }

  },

 created(){
        api.checkPayType().then((res) => {
            this.hasAlipay=res.data;
    });
},

  methods: {
    onWillingSubmit() {
      let skillCertificateFiles = this.willingForm.skillCertificateFiles.map(
        (v) => {
          return v.replace(process.env.VUE_APP_FILE_PATH, "");
        }
      );
      api
        .updateResume(
          Object.assign({}, this.willingForm, {
            skillCertificateFiles: skillCertificateFiles.toString(),
          }),
          { method: "post" }
        )
        .then((res) => {
          if (res.status == "200") {
            this.willingEditFlag = false;
            this.$store.dispatch("getUserInfo");
            this.$message({
              showClose: true,
              message: "求职意向成功！",
              type: "success",
            });
          } else {
            this.$message({
              showClose: true,
              message: res.message,
              type: "error",
            });
          }
        });
    },
    handleCancel() {
      this.willingEditFlag = false;
      this.handleSetWillingForm(this.user);
    },
    handleCityChange(arr) {
      this.willingForm.areaFullName = this.$refs["city"]
        .getCheckedNodes()[0]
        .pathLabels.join("/");
      this.willingForm.areaId = arr[1];
    },

    //用于刷新数据和取消动作数据复原
    handleSetWillingForm(v) {
      Object.assign(this.willingForm, {
        id: v.id,
        areaFullName: v.areaFullName,
        areaId: v.areaId,
        areaIdTemp: [],
        addr: v.addr,
        skillCertificateFiles: v.skillCertificateFiles,
        jobMemo: v.jobMemo,
      });
    },
  },
  filters:{
    desensitization:value=>{
      if(value!==""){
        return `${value.substring(0,4)}****${value.substring(14,18)}`
      }
    }
  },
};
</script>

<style lang="less" scoped>
.userCenterIndexPage {
  .textarea {
    /deep/ .el-textarea__inner {
      height: 120px;
    }
  }
  .willingForm {
    padding-top: 20px;
  }
  .detailForm {
    padding-bottom: 20px;
    /deep/ .el-form-item {
      margin-bottom: 0;
    }
  }
  .form_line {
    line-height: 20px;
    padding-top: 11px;
    word-break: break-all;
  }
  .baseInfo {
    .item {
      line-height: 30px;
      font-size: 14px;
      margin-bottom: 12px;
      label {
        color: #999;
        margin-right: 6px;
      }

      span.value {
        color: #333;
        cursor: pointer;

        &.fail {
          color: #ff3838;
        }

        &.success {
          color: #61d9a5;
        }
      }
    }

    .icon_gender {
      width: 16px;
      height: 16px;
    }
  }

  .task_item {
    padding: 10px 0;
  }

  .image_preview {
    position: relative;
    width: 200px;
    height: 40px;
    /deep/ .el-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      & > img {
        opacity: 0;
      }
    }
  }

  .line {
    margin-bottom: 10px;
    line-height: 30px;

    & + .line {
      border-top: 1px dashed #ebeef5;
      padding-top: 12px;
    }
  }

  .title {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    margin-right: 4px;
  }
  .companyInfo {
    display: inline-block;
    width: 748px;
    vertical-align: top;
    margin-top: -2px;
    .item {
      p {
        display: inline-block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        vertical-align: middle;
        margin: 0;
        line-height: 22px;
      }

      /deep/ .el-button {
        padding: 4px;
        margin-top: 4px;
        float: right;
      }
    }
  }
}
</style>